$primary-color: #ff9d00;

:root {
	// --df-messenger-button-titlebar-color: red !important;
	font-size: 14px;
	@media only screen and (min-width: 700px) {
		font-size: 16px;
	}
}

body {
	margin: 0 2rem;
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	background-color: #282c34;
	a {
		color: rgba($primary-color, 0.8);
		text-decoration: none;
		&:hover {
			color: $primary-color;
		}
	}
}

#main {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// font-size: 0.8rem;
	// @media only screen and (min-width: 700px) {
	// 	font-size: 1rem;
	// }
	color: white;
}

.App-link {
	color: #61dafb;
}

#logo {
	max-width: 200px;
	@media only screen and (min-width: 700px) {
		max-width: 300px;
	}
	margin-left: auto;
	margin-right: auto;
}

.section {
	margin-top: 2rem;
	max-width: 500px;
	@media only screen and (min-width: 700px) {
		margin-top: 5rem;
		max-width: 700px;
	}
}

#add {
	margin: 0 auto;
	width: auto;
	display: inline-flex;
	.social-button {
		width: 9rem;
	}
}

#features-container > div svg {
	width: 64px;
}

#features-container {
	display: grid;
	grid-template-columns: 1fr;
	> div {
		margin: 1rem;
		display: grid;
		grid-template-columns: 80px auto;
		align-items: center;
		text-align: left;
		div:nth-child(2) {
			padding-left: 1rem;
		}
	}
	h3 {
		margin-top: 0
	}
	@media only screen and (min-width: 700px) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		> div {
			display: block;
			padding: 0 1rem;
			text-align: center;
			margin: 0;
			div:nth-child(2) {
				padding-left: 0;
			}
		}
		h3 {
			margin-top: 1rem
		}
	}
}

#changelog th {
	font-weight: normal;
	text-align: left;
	padding: 0.2rem 1rem;
	vertical-align: top;
}

#bottom {
	height: 5rem;
}

.social-button-wrapper {
	position: relative;
	margin: 1rem;
	a {
		cursor: pointer;
		text-decoration: none;
		color: white;
		&:hover {
			color: white;
		}
		& + .tooltip {
			display: block;
			overflow: hidden;
			position: absolute;
			background: white;
			width: 0;
			height: 0;
			padding: 0;
			border-radius: 9px;
			left: 50%;
			transform: translateX(-50%) translateY(1px);
			svg, img {
				width: 100%;
				height: 100%;
			}
			transition-duration: 0.1s;
		}
		&:hover + .tooltip {
			display: block;
			height: 10rem;
			width: 10rem;
		}
	}
}

#support .social-button-wrapper {
	width: 12rem;
	margin: 1rem auto;
}

.social-button {
	svg {
		width: 1.5rem;
		margin: 0 auto;
	}
	font-size: 1rem;
	display: grid;
	grid-template-columns: 2rem auto;
	text-align: center;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	min-height: 1.5rem;
	padding: 0.5rem;
	border-radius: 9px;
	font-weight: 600;
	transition-duration: 0.1s;
	&:hover {
		transform: translateY(-0.1rem) scale(1.1);
	}
}

.color-line {
	fill: #01B901;
	background: #01B901;
}

.color-facebook {
	fill: #3b5998;
	background: #3b5998;
}

.color-kofi {
	fill: #29abe0;
	background: #29abe0;
}

.color-qr {
	fill: #0E3D67;
	background: #0E3D67;
}

.social-button.color-facebook {
	svg {
		height: 2rem;
		margin-bottom: -0.5rem;
	}
}

.social-button.color-kofi {
  svg {
    width: 2rem;
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
}

.social-button.color-qr {
  svg {
  	fill: white;
    width: 1.5rem;
    height: 1.5rem;
  }
}

df-messenger div.chat-wrapper[opened="true"] {
	height: 300px !important;
	width: 300px;
	color: red;
}

.fa-primary {
	fill: white;
}

.fa-secondary {
	fill: rgba(white, 0.7);
}